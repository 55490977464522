<template>
  <div class="page-create-app">
    <top-bar back backroute="selectSub" :params="{id: category.parent?.id}" :title="category.parent?.name || ''" />
    <div class="container">
      <div>
        <n-form>
          <div class="upload-image-place">
            <c-image-upload :file.sync="file" />
            <span v-if="$var('m1')" class="danger">{{$var('m1')}}</span>
          </div>
          <n-items>
            <div><t name="7.1" />: {{ category.name }}</div>

            <n-input :placeholder="$app.secure.clean($n.t('7.8'))" v-bind="$form.input('categoryInfo')"></n-input>

            <n-input :placeholder="$app.secure.clean($n.t('7.2'))" v-bind="$form.input('volume')"></n-input>

            <c-address-select class="select-address"
              :placeholder="$app.secure.clean($n.t('7.3'))"
              :data="addresses" v-bind="$form.input('address', 'select')" :update:value="selectAddress"
              @input="changeAddress">
            </c-address-select>


            <div class="map-place">
              <div id="map" style="width: 500px; height: 300px; display: none"></div>
            </div>

            <n-input :placeholder="$app.secure.clean($n.t('7.4'))" v-bind="$form.input('name')"></n-input>

            <n-input :placeholder="$app.secure.clean($n.t('7.9'))" v-bind="$form.input('description')"></n-input>

            <div class="to-top">
              <input :checked="toTop" type="checkbox" @click="toTop = !toTop">
              <div class="title"><t name="7.5" /></div>
              <div class="text"><t name="7.6" /></div>
            </div>
          </n-items>
        </n-form>
      </div>

      <n-button wide color="yellow" @click="save"><t name="7.7" /></n-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageCreateApp',
  data() {
    return {
      category: {},
      addresses: [],
      map: null,
      toTop: false,
      fileId: null,
      file: [],
      url: null,
    }
  },
  computed: {
    id() {
      return this.$route.params.categoryId
    },
  },
  watch: {
    file() {
      this.saveFile()
    }
  },
  mounted() {
    ymaps.ready(this.initMap)
  },
  created() {
    this.$form.init({
      categoryInfo: '',
      description: '',
      volume: '',
      address: {},
      name: '',
    })
    this.$form.rules({
      categoryInfo: [ 'required', ],
      description: [ 'required', ],
      volume: [ 'required', ],
      address: [ 'required', ],
      name: [ 'required', ],
    })
    this.load()
  },
  methods: {
    saveFile() {
      this.$var('load', true)
      if (this.file.length !== 0) {
        $api.files.create({ file: this.file, }).then((response) => {
          this.fileId = response.data.data.id
        }).catch(() => {
          this.$var('load', false)
        })
      }
    },
    initMap() {
      this.map = new ymaps.Map('map', {
        center: [ 43.233975, 76.948567, ],
        zoom: 12,
      })
      const address = this.$form.get('address')
      this.changeAddress(address || 'Алматы').then(() => {
        if (address && this.addresses[0]) {
          this.selectAddress(this.addresses[0])
        }
      })
    },
    selectAddress(v) {
      if (v.obj) {
        this.$form.set('address', v)
        this.map.geoObjects.removeAll()
        this.map.geoObjects.add(v.obj)
        this.map.setBounds(v.obj.properties.get('boundedBy'), {
          checkZoomRange: true,
        })
      }
    },
    changeAddress(v) {
      return ymaps.geocode(v, {
        boundedBy: this.map.getBounds(),
        results: 10,
      }).then((res) => {
        const addresses = []
        res.geoObjects.each((item) => {
          addresses.push({
            value: item.properties.get('uriMetaData.URI.uri'),
            title: item.properties.get('text'),
            coords: item.geometry.getCoordinates(),
            obj: item,
          })
        })
        this.addresses = addresses
        return res
      })
    },
    load() {
      this.$var('load', true)
      $api.categories.get(this.id).with('parent').then((response) => {
        this.category = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    save() {
      if (this.fileId !== null) {
        this.$var('load', true)
        const data = {
          volume: this.$form.get('volume'),
          address: this.$form.get('address.title'),
          latitude: this.$form.get('address.coords.0')+'',
          longitude: this.$form.get('address.coords.1')+'',
          name: this.$form.get('name'),
          description: this.$form.get('description'),
          categoryInfo: this.$form.get('categoryInfo'),
          checkbox: this.toTop,
          categoryId: this.category.id,
          image: this.fileId,
        }
        $api.application.create(data).then(() => {
          this.$router.push({ name: 'index', })
        }).catch((err) => {
        }).finally(() => {
          this.$var('load', true)
        })
      } else {
        this.$var('m1', 'Фото обязательно')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.page-create-app {
  .danger {
    margin-top: 10px;
    color: red;
  }
  .to-top{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8C8C8C;
  }
  .map-place {
    display: none;
  }
  .select-address {
    ::v-deep .n-wrapper {
      border: none;
    }
  }
}
</style>
